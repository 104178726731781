.loading-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(216, 216, 216, 0.7);
  backdrop-filter: blur(5px);
}

.loading--circular {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border: 0.3rem solid rgb(143, 139, 139);
  border-left: 0.3rem solid transparent;
  border-radius: 50%;
  background-color: transparent;
  animation: CircularLoading 700ms linear infinite;
  z-index: 120;
}

@keyframes CircularLoading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
