@font-face {
  font-family: "Vazir";
  src: url("./assets/fonts/Vazir-Regular-FD.ttf"),
    url("./assets/fonts/Vazir-Regular-FD.eot"),
    url("./assets/fonts/Vazir-Regular-FD.woff"),
    url("./assets/fonts/Vazir-Regular-FD.woff2");
}

* {
  font-family: "Vazir" !important;
}

html,
body {
  margin: 0;
  font-family: "Vazir" !important;
}
